import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import QueriesForm from "./QueriesForm";

const LayOut = ({ children }) => {
  return (
    <>
      {children}
      <Header />
      <Outlet />
      <QueriesForm />
    </>
  );
};

export default LayOut;
