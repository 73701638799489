import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Calculator from "./pages/Calculator";
import "./styles/style.css";
import LayOut from "./components/LayOut";
import Electric from "./pages/Electric";
import { Toaster } from "react-hot-toast";
import Directory from "./pages/Directory";
import Home from "./pages/Home";
import Business from "./pages/Business";
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./pages/Contact";
import About from "./pages/About";
import ThankYou from "./pages/ThankYou";
import Blogs from "./pages/Blogs";
import ScrollToTop from "./components/ScrollToTop";
import Blog1 from "./pages/Blog1";
import Book from "./pages/Book";
import CongratulationsDetail from "./pages/CongratulationsDetail";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/Blog4";
import SolarDetails from "./pages/SolarDetails";
import BlogDetails from "./pages/BlogDetails";
import Review from "./pages/Review";
import Directory2 from "./pages/Directory2";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LayOut />}>
            <Route index element={<Home />} />
            <Route path="/solar" element={<Calculator />} />
            <Route path="/electric" element={<Electric />} />
            <Route path="/solar-panel-installer-directory" element={<Directory />} />
            <Route path="/solar-panel-installer-directory/:districtParam" element={<Directory2 />} />
            <Route path="/solar-panel-installer/:id" element={<SolarDetails />} />
            <Route path="/solar-panel-installer-review" element={<Review />} />
            <Route path="/business-form" element={<Business />} />
            <Route path="/book-site-visit" element={<Book />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogDetails/>} />
            <Route path="/blog/how-solar-panel-works-on-home-rooftop" element={<Blog1/>} />
            <Route path="/blog/pm-surya-ghar-yojana" element={<Blog2/>} />
            <Route path="/blog/cost-of-solar-on-grid-system" element={<Blog3/>} />
            <Route path="/blog/applicances-that-can-run-on-solar-power" element={<Blog4/>} />
            <Route path="/thanks" element={<ThankYou />} />
            <Route path="/congratulations-detail" element={<CongratulationsDetail />}/>
          </Route>
        </Routes>
        <Toaster position="top-right" />
      </Router>
    </>
  );
}

export default App;
