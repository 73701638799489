import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const initialState = {
  usageType: "",
  averageMonthlyBill: "",
  costOfElectricity: "9",
  loanPeriod: "7",
  loanInterestRate: "7",
};

const Calculator = () => {
  const [userData, setUserData] = useState(initialState);

  const [powerConsumption, setPowerConsumption] = useState("");
  const [capacityOfPvPlant, setCapacityOfPvPlant] = useState("");
  const [recommendedPvPlantSize, setRecommendedPvPlantSize] = useState("");

  const [shadeFreeSpace, setShadeFreeSpace] = useState("");
  const [sideOfSquare, setSideOfSquare] = useState("");

  const [installationCostWithoutSubsidy, setInstallationCostWithoutSubsidy] =
    useState("");
  const [subsidyAmount, setSubsidyAmount] = useState("");
  const [netInstallationCostWithSubsidy, setNetInstallationCostWithSubsidy] =
    useState("");

  const [electricityProducedFromSolar, setElectricityProducedFromSolar] =
    useState("");
  const [costOfElectricityFromSolar, setCostOfElectricityFromSolar] =
    useState("");

  const [payBackPeriod, setpayBackPeriod] = useState("");
  const [discountedPaybackPeriod, setDiscountedPaybackPeriod] = useState("");

  const [emiAmmount, setEmiAmmount] = useState("");
  const [billFromTheGrid, setbillFromTheGrid] = useState("");
  const [
    monthlyNetPayableAfterSolarSystem,
    setMonthlyNetPayableAfterSolarSystem,
  ] = useState("");
  const [
    savingsOnMonthlyPaymentApplicableTillLoan,
    setSavingsOnMonthlyPaymentApplicableTillLoan,
  ] = useState("");
  const [
    savingsOnMonthlyPaymentAfterTheLoan,
    setSavingsOnMonthlyPaymentAfterTheLoan,
  ] = useState("");

  const [contributionToTheEnvironment, setContributionToTheEnvironment] =
    useState("");
  const [savingsOnMonthlyBill, setSavingsOnMonthlyBill] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const calculation = () => {
      if (userData.usageType === "1") {
        if (userData.averageMonthlyBill && userData.costOfElectricity) {
          // Average Monthly Power Consumption (units)
          const averageMonthlyPowerConsumptionValue = (
            userData.averageMonthlyBill / userData.costOfElectricity
          ).toFixed();
          const averageMonthlyPowerConsumptionNumber = parseFloat(
            averageMonthlyPowerConsumptionValue
          );

          setPowerConsumption(averageMonthlyPowerConsumptionNumber);

          const globalIrradiance = 4;
          const tillFactor = 1.1;
          const efficiencyOfThePv = 75;

          // Required Capacity of PV Plant
          const capacityOfPvPlantValue = (
            (averageMonthlyPowerConsumptionNumber /
              (30 * globalIrradiance * tillFactor * efficiencyOfThePv)) *
            100
          ).toFixed(1);

          const capacityOfPvPlantValueNumber = parseFloat(
            capacityOfPvPlantValue
          );
          setCapacityOfPvPlant(capacityOfPvPlantValueNumber);

          // Recommended PV Plant size
          const recommendedPvPlantSizeValue =
            Math.floor(capacityOfPvPlantValueNumber / 0.5) * 0.5;
          setRecommendedPvPlantSize(recommendedPvPlantSizeValue);

          // Shade-free Space Required
          const shadeFreeSpaceValue = recommendedPvPlantSizeValue * 100;
          setShadeFreeSpace(shadeFreeSpaceValue);

          // side of square
          const squareRootOfShadeFreeSpace =
            Math.sqrt(shadeFreeSpaceValue).toFixed(1);
          const squareRootOfShadeFreeSpaceNumber = parseFloat(
            squareRootOfShadeFreeSpace
          );
          setSideOfSquare(squareRootOfShadeFreeSpaceNumber);

          // Installation Cost without subsidy
          const installationCostWithoutSubsidyValue =
            recommendedPvPlantSizeValue * 64000;
          setInstallationCostWithoutSubsidy(
            installationCostWithoutSubsidyValue
          );

          // Subsidy amount (if applicable)
          function calculateCost(value) {
            if (value <= 3) {
              return value * 14588;
            } else if (value < 10) {
              return 3 * 14588 + 7294 * (value - 3);
            } else if (value > 10) {
              return 94822;
            } else {
              return 0;
            }
          }
          const subsidyAmountValue = calculateCost(recommendedPvPlantSizeValue);
          setSubsidyAmount(subsidyAmountValue);

          // Net Installation Cost with Subsidy
          const netInstallationCostWithSubsidyValue =
            installationCostWithoutSubsidyValue - subsidyAmountValue;
          setNetInstallationCostWithSubsidy(
            netInstallationCostWithSubsidyValue
          );

          // Electricity Rate Escalator
          const electricityRateEscalatorValue = 0;

          // Monthly value of electricity produced from solar
          const electricityProducedFromSolarValue = (
            (recommendedPvPlantSizeValue *
              efficiencyOfThePv *
              tillFactor *
              globalIrradiance *
              30 *
              userData.costOfElectricity) /
            100
          ).toFixed(1);
          const electricityProducedFromSolarValueNumber = parseFloat(
            electricityProducedFromSolarValue
          );
          setElectricityProducedFromSolar(
            electricityProducedFromSolarValueNumber
          );

          // Cost of electricity taken from the grid  after solar system installation
          const costOfElectricityFromSolarValue = (
            userData.averageMonthlyBill -
            electricityProducedFromSolarValueNumber
          ).toFixed(1);
          const costOfElectricityFromSolarValueNumber = parseFloat(
            costOfElectricityFromSolarValue
          );
          setCostOfElectricityFromSolar(costOfElectricityFromSolarValueNumber);

          // Discount Rate
          const discountRate = 8;

          // Payback Period
          const payBackPeriodValue = (
            netInstallationCostWithSubsidyValue /
            (12 * electricityProducedFromSolarValueNumber)
          ).toFixed(1);
          const payBackPeriodValueNumber = parseFloat(payBackPeriodValue);
          setpayBackPeriod(payBackPeriodValueNumber);

          // Discounted Payback Period
          const discountPaybackPeriod = (
            -Math.log(1 - (74118 * (8 / 100)) / (12 * 1193.94)) /
            Math.log(1 + 8 / 100)
          ).toFixed(1);
          const discountPaybackPeriodNumber = parseFloat(discountPaybackPeriod);
          setDiscountedPaybackPeriod(discountPaybackPeriodNumber);

          // Loan Interest rate
          const loanInterestRate = userData.loanInterestRate;

          // EMI Amount
          const monthlyInterestRate = loanInterestRate / 12 / 100;
          const numberOfMonths = userData.loanPeriod * 12;

          const pmt = (
            netInstallationCostWithSubsidyValue *
            (monthlyInterestRate /
              (1 - Math.pow(1 + monthlyInterestRate, -numberOfMonths)))
          ).toFixed(1);

          const emiAmount = parseFloat(pmt);
          setEmiAmmount(emiAmount);

          // Bill from the grid
          const billFromTheGridValue = costOfElectricityFromSolarValueNumber;
          setbillFromTheGrid(billFromTheGridValue);

          // Monthly net payable amount after solar system installation
          const monthlyNetPayableAfterSolarSystemValue = (
            emiAmount + billFromTheGridValue
          ).toFixed(1);
          const monthlyNetPayableAfterSolarSystemValueNumber = parseFloat(
            monthlyNetPayableAfterSolarSystemValue
          );
          setMonthlyNetPayableAfterSolarSystem(
            monthlyNetPayableAfterSolarSystemValueNumber
          );

          // % savings on monthly payment after solar system installation (applicable till loan is repayed )
          const savingsOnMonthlyPaymentApplicableTillLoanValue = (
            ((userData.averageMonthlyBill -
              monthlyNetPayableAfterSolarSystemValueNumber) /
              userData.averageMonthlyBill) *
            100
          ).toFixed(1);

          const savingsOnMonthlyPaymentApplicableTillLoanValueNumber =
            parseFloat(savingsOnMonthlyPaymentApplicableTillLoanValue);

          setSavingsOnMonthlyPaymentApplicableTillLoan(
            savingsOnMonthlyPaymentApplicableTillLoanValueNumber
          );

          // % savings on monthly payment after solar system installation (after the loan is repayed )
          const savingsOnMonthlyPaymentAfterTheLoanValue = (
            ((userData.averageMonthlyBill - billFromTheGridValue) /
              userData.averageMonthlyBill) *
            100
          ).toFixed(1);

          const savingsOnMonthlyPaymentAfterTheLoanValueNuber = parseFloat(
            savingsOnMonthlyPaymentAfterTheLoanValue
          );

          setSavingsOnMonthlyPaymentAfterTheLoan(
            savingsOnMonthlyPaymentAfterTheLoanValueNuber
          );

          // Contribution to the Environment
          const contributionToTheEnvironmentValue = (
            emiAmount / userData.averageMonthlyBill
          ).toFixed(1);
          const contributionToTheEnvironmentValueNumber = parseFloat(
            contributionToTheEnvironmentValue
          );
          setContributionToTheEnvironment(
            contributionToTheEnvironmentValueNumber
          );

          // Savings on Monthly bill
          const savingsOnMonthlyBillValue = (
            (1 -
              (emiAmount +
                savingsOnMonthlyPaymentAfterTheLoanValueNuber / 100) /
              userData.averageMonthlyBill) *
            100
          ).toFixed(1);

          const savingsOnMonthlyBillValuenumber = parseFloat(
            savingsOnMonthlyBillValue
          );
          setSavingsOnMonthlyBill(savingsOnMonthlyBillValuenumber);
        }
      } else {
        setPowerConsumption("");
        setCapacityOfPvPlant("");
        setRecommendedPvPlantSize("");
        setShadeFreeSpace("");
        setSideOfSquare("");
        setInstallationCostWithoutSubsidy("");
        setSubsidyAmount("");
        setNetInstallationCostWithSubsidy("");
      }
    };

    calculation();
  }, [userData]);

  // const userId = "";
  // const clickTime = new Date(Date.now()).toLocaleString();
  // console.log({ userId, page: "Solar", clickTime });

  return (
    <>
      <div className="calculator_section">
        <div className="container">
          <div className="top_text">
            <h1>Solar System Calculator</h1>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="solar_calculator_left_side">
                <form>
                  <div className="mb-4">
                    <label htmlFor="usageType" className="form-label">
                      Type
                    </label>
                    <select
                      className="form-select"
                      id="usageType"
                      onChange={handleChange}
                      name="usageType"
                    >
                      <option value="">Select</option>
                      <option value="1">Residential</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="averageMonthlyBill" className="form-label">
                      Average Monthly Electricity Bill (Rs)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="averageMonthlyBill"
                      placeholder="Enter average monthly bill"
                      required
                      name="averageMonthlyBill"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="costOfElectricity" className="form-label">
                      Cost of Electricity Per Unit (ie. Rs/kWhr)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="costOfElectricity"
                      placeholder="Enter cost of electricity"
                      required
                      value={userData.costOfElectricity}
                      name="costOfElectricity"
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="mb-4">
                                        <label htmlFor="loanPeriod" className="form-label">Loan Period (Years)</label>
                                        <select className="form-select" id='loanPeriod' onChange={handleChange} name='loanPeriod'>
                                            <option value={10}>7</option>
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="costOfElectricity" className="form-label">Loan Interest rate (percentage)</label>
                                        <input type="number"
                                            className="form-control"
                                            id="loanInterestRate"
                                            placeholder='Enter loan interest rate'
                                            readOnly
                                            name='loanInterestRate'
                                            onChange={handleChange} value={userData.loanInterestRate} />
                                    </div> */}
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="solar_calculator_right_side">
                {/* <h3>Required Plant size</h3> */}
                <div className="mb-3 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Average Monthly Power Consumption</p>
                  </div>
                  <div className="right_side_text text-end">
                    <h6 className="m-0">
                      {powerConsumption.length === 0
                        ? 0
                        : powerConsumption.toFixed(0)}{" "}
                      units
                    </h6>
                  </div>
                </div>
                <div>
                  <p className="mb-3 p-txt">
                    Purchasing new appliance or electric vehicle?
                  </p>
                  <p className="mb-3 p-txt">
                    Calulate incremental electricity required using{" "}
                    <Link to={"/electric"}>
                      {" "}
                      Electricity Consumption Estimator
                    </Link>{" "}
                  </p>
                </div>
                {/* <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Global Horizontal Irradiance (kWh/kWHp/day)</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>4</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Tilt Factor</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>1.1</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Efficiency of the PV system</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>75.00%</h6>
                                    </div>
                                </div> */}
                {/* <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Required Capacity of PV Plant</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>{capacityOfPvPlant.length === 0 ? 0 : capacityOfPvPlant} kW</h6>
                                    </div>
                                </div> */}
                <div className="mb-3 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Recommended PV Plant size</p>
                  </div>
                  <div className="right_side_text text-end">
                    <h6 className="m-0">
                      {recommendedPvPlantSize.length === 0
                        ? 0
                        : recommendedPvPlantSize.toFixed(2)}{" "}
                      kW
                    </h6>
                  </div>
                </div>
                {/* <h3>Space constraint</h3> */}
                <div className="mb-3 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Shade-free Space Required</p>
                  </div>
                  <div className="right_side_text text-end">
                    <h6 className="m-0">
                      {shadeFreeSpace.length === 0
                        ? 0
                        : shadeFreeSpace.toFixed(2)}{" "}
                      Sq.ft
                    </h6>
                  </div>
                </div>
                {/* <div className='mb-4 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>side of square</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>{sideOfSquare.length === 0 ? 0 : sideOfSquare} ft</h6>
                                    </div>
                                </div>
                                <h3>Cost without loan</h3>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Installation Cost without subsidy</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{installationCostWithoutSubsidy.length === 0 ? 0 : installationCostWithoutSubsidy}</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Subsidy amount (if applicable)</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{subsidyAmount.length === 0 ? 0 : subsidyAmount}</h6>
                                    </div>
                                </div>
                                <div className='mb-4 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Net Installation Cost with Subsidy</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{netInstallationCostWithSubsidy.length === 0 ? 0 : netInstallationCostWithSubsidy}</h6>
                                    </div>
                                </div>
                                <h3>Electricity Rate</h3>
                                <div className='mb-4 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Electricity Rate Escalator</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>0.00%</h6>
                                    </div>
                                </div>
                                <h3>Benefit</h3>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Monthly value of electricity produced from solar</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{electricityProducedFromSolar.length === 0 ? 0 : electricityProducedFromSolar}</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Cost of electricity taken from the grid  after solar system installation</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{costOfElectricityFromSolar.length === 0 ? 0 : costOfElectricityFromSolar}</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Discount Rate</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>8%</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Payback Period</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>{payBackPeriod.length === 0 ? 0 : payBackPeriod} Years</h6>
                                    </div>
                                </div>
                                <div className='mb-4 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Discounted Payback Period</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>{discountedPaybackPeriod.length === 0 ? 0 : discountedPaybackPeriod} Years</h6>
                                    </div>
                                </div>
                                <h3>Payment schedule with loan</h3>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>EMI Amount (Per month)</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{emiAmmount.length === 0 ? 0 : emiAmmount}</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Bill from the grid </p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{billFromTheGrid.length === 0 ? 0 : billFromTheGrid}</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Monthly net payable amount after solar system installation</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{monthlyNetPayableAfterSolarSystem.length === 0 ? 0 : monthlyNetPayableAfterSolarSystem}</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>% savings on monthly payment after solar system installation (applicable till loan is repayed )</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>{savingsOnMonthlyPaymentApplicableTillLoan.length === 0 ? 0 : savingsOnMonthlyPaymentApplicableTillLoan}%</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>% savings on monthly payment after solar system installation (after the loan is repayed )</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>{savingsOnMonthlyPaymentAfterTheLoan.length === 0 ? 0 : savingsOnMonthlyPaymentAfterTheLoan}%</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Contribution to the Environment</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{contributionToTheEnvironment.length === 0 ? 0 : contributionToTheEnvironment}</h6>
                                    </div>
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Savings on Monthly bill</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>{savingsOnMonthlyBill.length === 0 ? 0 : savingsOnMonthlyBill}%</h6>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculator;
