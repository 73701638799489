import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";

const Book = () => {
  const [formData, setFormData] = useState({
    name: "",
    // email: "",
    phoneNumber: "",
    address: "",
    // buildingType: "",
    // roofType: "",
    // roofAge: "",
    // queOneDate: "",
    // queOneTime: "",
    // queTwoDate: "",
    // queTwoTime: "",
    comment: "",
    image: null,
    frontView: null,
    terraceView: null,
  });

  // const today = new Date().toISOString().split("T")[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const input = value.replace(/\D/g, "").slice(0, 15);
      setFormData((prevData) => ({
        ...prevData,
        [name]: input,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeMB = 1;

    if (selectedFile) {
      if (selectedFile.size > maxSizeMB * 1024 * 1024) {
        toast.error(`File size exceeds ${maxSizeMB}MB.`);
        e.target.value = null;
        return;
      }
      setFormData({ ...formData, image: selectedFile });
    }
  };

  const handleUploadImage = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const maxSizeMB = 1;
    const oversizedFiles = selectedFiles.filter(
      (file) => file.size > maxSizeMB * 1024 * 1024
    );
    if (oversizedFiles.length > 0) {
      toast.error(`File size exceeds ${maxSizeMB}MB.`);
      e.target.value = null;
      return;
    }
    if (selectedFiles.length > 2) {
      toast.error("You can only upload a maximum of two images.");
      e.target.value = null;
      return;
    }
    if (selectedFiles.length < 1) {
      toast.error("Please select at least one image.");
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: selectedFiles,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      // for (const key in formData) {
      //   formDataToSend.append(key, formData[key]);
      // }
      formDataToSend.append("name", formData.name);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("comment", formData.comment);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("image", formData.image);
      formDataToSend.append("frontView", formData.frontView[0]);
      formDataToSend.append("frontView", formData.frontView[1]);
      formDataToSend.append("terraceView", formData.terraceView[0]);
      formDataToSend.append("terraceView", formData.terraceView[1]);
      const response = await axios.post(
        "https://admin.solarvipani.com/book-site-visit",
        formDataToSend
      );
      if (!response.data.res) {
        toast.error("FormData are not added!");
      } else {
        setFormData({
          name: "",
          // email: "",
          phoneNumber: "",
          address: "",
          // buildingType: "",
          // roofType: "",
          // roofAge: "",
          // queOneDate: "",
          // queOneTime: "",
          // queTwoDate: "",
          // queTwoTime: "",
          comment: "",
          image: null,
          frontView: null,
          terraceView: null,
        });
        document.getElementById("image").value = null;
        document.getElementById("frontView").value = null;
        document.getElementById("terraceView").value = null;
      }
    } catch (error) {
      toast.error("Error fetching form data: " + error.message);
    }
  };

  return (
    <>
      <section className="book_section">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="text mb-3">
              <h5>
                Book Consultation visit for site survey, design layout and
                quotation.
              </h5>
            </div>
            <div className="form_fields row">
              <h4 className="form-title my-4">Contact Information</h4>
              <div className="col-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone
                </label>
                <input
                  type="number"
                  name="phoneNumber"
                  className="form-control"
                  id="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div> */}
              {/* <h4 className="form-title my-4">Property Details</h4> */}
              <div className="col-12 mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="2"
                  className="w-100"
                  value={formData.address}
                  onChange={handleChange}
                ></textarea>
              </div>
              {/* <div className="col-4 mb-3">
                <label htmlFor="buildingType" className="form-label">
                  Building Type
                </label>
                <select
                  className="form-select"
                  id="buildingType"
                  name="buildingType"
                  value={formData.buildingType}
                  onChange={handleChange}
                >
                  <option value={""}>Select</option>
                  <option value={"Standalone"}>Standalone</option>
                  <option value={"Apartment"}>Apartment</option>
                  <option value={"Other"}>Other</option>
                </select>
              </div>
              <div className="col-4 mb-3">
                <label htmlFor="roofType" className="form-label">
                  Roof Type
                </label>
                <select
                  className="form-select"
                  id="roofType"
                  name="roofType"
                  value={formData.roofType}
                  onChange={handleChange}
                >
                  <option value={""}>Select</option>
                  <option value={"Shingle"}>Shingle</option>
                  <option value={"Tile"}>Tile</option>
                  <option value={"Metal"}>Metal</option>
                  <option value={"Flat"}>Flat</option>
                  <option value={"Other"}>Other</option>
                </select>
              </div>
              <div className="col-4 mb-3">
                <label htmlFor="roofAge" className="form-label">
                  Roof Age
                </label>
                <select
                  className="form-select"
                  id="roofAge"
                  name="roofAge"
                  value={formData.roofAge}
                  onChange={handleChange}
                >
                  <option value={""}>Select</option>
                  <option value={"Less than 5 years"}>Less than 5 years</option>
                  <option value={"5-10 years"}>5-10 years</option>
                  <option value={"10-15 years"}>10-15 years</option>
                  <option value={"15-20 years"}>15-20 years</option>
                  <option value={"More than 20 years"}>
                    More than 20 years
                  </option>
                </select>
              </div>
              <h4 className="form-title my-4">
                Preferred Date and Time for Site Visit
              </h4>
              <h3 className="form-sub-title mb-2">
                Option 1
              </h3>
              <div className="col-6 mb-3">
                <label htmlFor="queOneDate" className="form-label">
                  Date:
                </label>
                <input
                  type="date"
                  id="queOneDate"
                  name="queOneDate"
                  className="form-control mb-3"
                  min={today}
                  value={formData.queOneDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="queOneTime" className="form-label">
                  Time:
                </label>
                <select
                  id="queOneTime"
                  name="queOneTime"
                  className="form-select"
                  value={formData.queOneTime}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Time</option>
                  {Array.from({ length: 9 }, (_, i) => {
                    const hour = i + 10;
                    const amPm = hour < 12 ? "AM" : "PM";
                    const displayHour = hour <= 12 ? hour : hour - 12;
                    return (
                      <option
                        key={i}
                        value={`${displayHour}:00 ${amPm}`}
                      >{`${displayHour}:00 ${amPm}`}</option>
                    );
                  })}
                </select>
              </div>
              <h3 className="form-sub-title mb-2">
                Option 2
              </h3>
              <div className="col-6 mb-3">
                <label htmlFor="queTwoDate" className="form-label">
                  Date:
                </label>
                <input
                  type="date"
                  id="queTwoDate"
                  name="queTwoDate"
                  className="form-control mb-3"
                  min={today}
                  value={formData.queTwoDate}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="queTwoTime" className="form-label">
                  Time:
                </label>
                <select
                  id="queTwoTime"
                  name="queTwoTime"
                  className="form-select"
                  value={formData.queTwoTime}
                  onChange={handleChange}
                >
                  <option value="">Select Time</option>
                  {Array.from({ length: 9 }, (_, i) => {
                    const hour = i + 10;
                    const amPm = hour < 12 ? "AM" : "PM";
                    const displayHour = hour <= 12 ? hour : hour - 12;
                    return (
                      <option
                        key={i}
                        value={`${displayHour}:00 ${amPm}`}
                      >{`${displayHour}:00 ${amPm}`}</option>
                    );
                  })}
                </select>
              </div> */}
              <div className="col-12 mb-3">
                <label htmlFor="electricityBill" className="form-label">
                  Upload photo/copy of recent electricity bill (regular file
                  formats)
                </label>
                <input
                  type="file"
                  accept=".pdf,.jpg,.png"
                  className="form-control"
                  id="image"
                  name="image"
                  onChange={handleImageChange}
                  required
                />
                <small className="form-text text-muted">
                  Max file size: 1MB. Allowed formats: PDF, JPG, PNG.
                </small>
              </div>
              <div className="col-12 mb-3">
                <label className="form-label">
                  Upload Front view photo of the building
                </label>
                <input
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  className="form-control"
                  id="frontView"
                  name="frontView"
                  onChange={handleUploadImage}
                  multiple
                  required
                />
                <small className="form-text text-muted">
                  Min 1 image and max 2 images. Allowed formats: JPG, PNG.
                </small>
              </div>
              <div className="col-12 mb-3">
                <label className="form-label">
                  Upload photo of the terrace
                </label>
                <input
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  className="form-control"
                  id="terraceView"
                  name="terraceView"
                  onChange={handleUploadImage}
                  multiple
                  required
                />
                <small className="form-text text-muted">
                  Min 1 image and max 2 images. Allowed formats: JPG, PNG.
                </small>
              </div>
              <h4 className="form-title mt-3 mb-2">Additional Comments</h4>
              <div className="col-12 mb-3">
                <textarea
                  name="comment"
                  id="comment"
                  cols="30"
                  rows="5"
                  className="w-100"
                  value={formData.comment}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-12">
                <button type="submit" className="submit_btn w-100">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Book;
