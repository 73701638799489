import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [blogData, setBlogData] = useState([]);

  const navigation = useNavigate();

  const handleSetId = (data) => {
    localStorage.setItem("blogID", data?.id);
    setTimeout(() => {
      navigation(`/blogs/${data.url}`);
    }, 300);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://admin.solarvipani.com/blogs");

        setBlogData(response.data.data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="blog_section">
        <div className="container">
          {blogData.length > 0 ? (
            blogData.map((blog, i) => {
              // let redirectTo = null;

              // if (blog.url) {
              //   redirectTo = `/blogs-details-url/${blog.id}`;
              // } else if (blog.details) {
              //   redirectTo = `/blogs-details/${blog.id}`;
              // }

              return (
                <div className="how_work blog_box" key={i}>
                  <div className="top_text">
                    <h5>{blog.title}</h5>
                  </div>

                  <div className="text">
                    <p className="mb-3">{blog.description}</p>
                  </div>

                  {/* {redirectTo ? ( */}
                  <button className="blog_btn" onClick={() => handleSetId(blog)}>
                    Read More
                  </button>
                  {/* ) : null} */}
                </div>
              );
            })
          ) : (
            <div className="text-center">
              <h1>Blogs not found</h1>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Blogs;
