import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import StateValue from "../data/state.json";
import axios from "axios";

const Blog2 = () => {
  const [formData, setFormData] = useState({
    systemSize: "",
    type: "",
    pageType: "",
    name: "",
    email: "",
    phoneNumber: "",
    state: "",
    city: "",
  });

  const location = useLocation();

  const navigation = useNavigate();

  const stateNames = Object.keys(StateValue);

  useEffect(() => {
    let pageType = "";
    if (location.pathname === "/blog/pm-surya-ghar-yojana") {
      pageType = "pm-surya-ghar-yojana";
    } else if (location.pathname === "/blog/Cost-of-solar-on-grid-system") {
      pageType = "cost-of-solar-on-grid-system";
    } else if (location.pathname === "/") {
      pageType = "Home";
    }

    setFormData({
      ...formData,
      pageType: pageType,
    });
  }, [location.pathname]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      const input = value.replace(/\D/g, "").slice(0, 15);
      setFormData((prevData) => ({
        ...prevData,
        [name]: input,
      }));
    } else if (name === "state") {
      setFormData((prevData) => ({
        ...prevData,
        state: value,
        city: "",
      }));
    } else if (name === "city") {
      setFormData((prevData) => ({
        ...prevData,
        city: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://admin.solarvipani.com/user-quote",
        formData
      );

      if (!response.data.res) {
        toast.error("Quate is not added!");
      } else {
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          state: "",
          city: "",
          type: "",
          systemSize: "",
          pageType: location.pathname,
        });

        navigation("/thanks");
      }
    } catch (error) {
      toast.error("Error fetch form data" + error.message);
    }
  };

  const selectedStateData = formData.state
    ? StateValue[formData.state].data
    : [];
  return (
    <>
      <div className="rooftop_main blog-main">
        <div className="container">
          <h4 className="rooftop_title">
            PM Surya Ghar Yojana : Your Guide to check eligibility and steps to
            benefit from the scheme
          </h4>
          <div className="rooftop_content">
            <p className="rooftop_desc mb-4">
              To help households benefit from the solar photovoltaic technology
              to generate green electricity at cheaper rates and save money, the
              government launched PM Surya Ghar Yojana on 13th February 2024.
              Under the scheme residential households, residential welfare
              associations are eligible for up to ₹78,000 installation subsidy.
              It is important to understand the eligibility criteria and steps
              to benefit from the scheme without delay.
            </p>
            <h5 className="rooftop_subtitle">Eligibility:</h5>
            <ol>
              <li>The applicant must be a citizen of India.</li>
              <li>
                The applicant must own a house which is suitable for installing
                solar panel systems.
              </li>
              <li>
                The household should have an active electricity connection with
                the electricity distribution company.
              </li>
              <li>
                The solar panel installation should be done by an Empanelled
                Vendor.
              </li>
            </ol>
            <h5 className="rooftop_subtitle">Benefits:</h5>
            <h6 className="rooftop_number_title">For individual homeowners:</h6>
            <p className="rooftop_desc mb-1">
              Government provides subsidies on installation of solar rooftop
              systems.
            </p>
            <p className="rooftop_desc mb-1">Rs. 30,000/- per kW up to 2 kW</p>
            <p className="rooftop_desc mb-1">
              Rs. 18,000/- per kW for additional capacity up to 3 kW
            </p>
            <p className="rooftop_desc mb-3">
              Total Subsidy for systems larger than 3 kW capped at Rs 78,000
            </p>
            <p className="rooftop_desc mb-3">
              For Group Housing Society/ Resident Welfare Association (GHS/RWA)
              Subsidy for Group Housing Society/ Resident Welfare Association
              (GHS/RWA) Rs. 18,000 per kW for common facilities, including EV
              charging, up to 500 kW capacity (@3 kW per house) with the upper
              limit being inclusive of individual rooftop plants installed by
              individual residents in the GHS/RWA
            </p>
          </div>
          <div className="blog-right-side mt-5">
            <form onSubmit={handleSubmit}>
              <div className="text mb-3">
                <h5>
                  Book Consultation Call to get a Quote
                </h5>
              </div>
              <div className="form_fields row">
                <div className="col-12 mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="city" className="form-label">
                    State
                  </label>
                  <select
                    className="form-select form-select-lg mb-3"
                    id="state"
                    name="state"
                    onChange={handleChange}
                    value={formData.state}
                    required
                  >
                    <option value={""}>Select</option>
                    {stateNames.map((v, i) => (
                      <option value={v} key={i}>
                        {StateValue[v].name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <select
                    className="form-select form-select-lg mb-3"
                    id="city"
                    name="city"
                    required
                    onChange={handleChange}
                    value={formData.city}
                  >
                    <option value={"1"}>Select</option>
                    {selectedStateData.map((district, i) => (
                      <option value={district} key={i}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="type" className="form-label">
                      Type
                    </label>
                    <select
                      className="form-select form-select-lg mb-3"
                      id="type"
                      name="type"
                      required
                      onChange={handleChange}
                      value={formData.type}
                    >
                      <option value="">Select</option>
                      <option value="Residential - Independant home">
                        Residential - Independant home
                      </option>
                      <option value="Residential - Apartment/Housing society">
                        Residential - Apartment/Housing society
                      </option>
                      <option value="Commercial/Business">
                        Commercial/Business
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <button type="submit" className="submit_btn w-100">
                    Get Quote
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog2;
