import React from "react";

const Blog1 = () => {
  return (
    <>
      <div className="rooftop_main">
        <div className="container">
          <h4 className="rooftop_title">
            How does solar panel work on the home rooftop ?
          </h4>
          <div className="rooftop_content">
            <p className="rooftop_desc mb-4">
              In recent years, there has been a significant surge in the
              adoption of renewable energy sources, with solar power leading the
              charge. One of the most accessible and popular forms of solar
              energy generation is rooftop solar panels. These panels, also
              known as photovoltaic (PV) systems, offer homeowners and
              businesses the opportunity to generate clean, sustainable
              electricity right from their rooftops. But how exactly do rooftop
              solar panels work? Let&#39;s delve into the fascinating world of
              solar energy to find out.
            </p>
            <h5 className="rooftop_subtitle">
              Capturing Solar Energy: What is The Photovoltaic Effect?
            </h5>
            <p className="rooftop_desc mb-4">
              At the heart of rooftop solar panels lies a remarkable phenomenon
              known as the photovoltaic effect. This effect occurs when certain
              materials, typically silicon-based semiconductors, interact with
              photons (particles of light) and generate an electric current.
              Each solar panel is composed of numerous solar cells, which are
              interconnected to form a module. When sunlight hits these cells,
              it excites the electrons within the semiconductor material,
              causing them to flow and create an electrical current.
            </p>
            <h5 className="rooftop_subtitle">
              How does a rooftop solar power panel system function?
            </h5>
            <p className="rooftop_desc mb-4">
              An area with good insolation, with no obstacles (actual and
              potential) and long periods of sunshine in a year is conducive for
              solar power generation. With that being said, the other thing
              which matters for solar energy is a good solar panel system.
            </p>
            <p className="rooftop_desc mb-4">
              A solar power panel system is the set of components which
              eventually provides working current for the household, or a
              commercial installation. There are several components of a solar
              panel system, and they are described below.
            </p>
            <h6 className="rooftop_number_title">1) Solar Panel:</h6>
            <p className="rooftop_number_desc mb-4">
              The solar panel is the medium by which electricity is generated.
              These panels are made up of solar cells or photovoltaic cells (PV
              cells). By the principles of the photoelectric effect, electrons
              are emitted when sunlight falls upon these cells. These electrons
              form the basis of electric current for our usage. Depending on the
              type of solar panels, the number of electrons released can vary,
              which can vary the efficiency of the system. Solar panels are
              classified on the nature of their crystal structure, and the
              primary element forming the crystal structure. Some types are
              mono-crystalline silicon, polycrystalline, PERC, and thin film
              solar panels.
            </p>
            <h6 className="rooftop_number_title">2) Mounting Structure:</h6>
            <p className="rooftop_number_desc mb-4">
              The mounting structure provides the structural support to the
              solar panel system. They can be either ground mounted or rooftop
              mounted. They are made of lightweight and strong materials for
              ensuring long life. Mounting structures can affect the efficiency
              of the system based on where on the rooftop they have been
              erected, the angle of inclination of the structure, among others.
            </p>
            <h6 className="rooftop_number_title">3) Solar inverter:</h6>
            <p className="rooftop_number_desc mb-4">
              The solar inverter is a device which changes the direction of the
              generated current. When sunlight strikes the solar panel, direct
              current (DC) is generated. The inverter converts this DC into
              usable alternating current (AC) for home appliances. They occur in
              the string form and in the micro form. String inverters are a
              central type of inverter, whereas the micro-inverters are attached
              to each solar panel. Depending on the type of power systems
              (described ahead), the type of inverters also varies, though their
              basic function remains the same.
            </p>
            <h6 className="rooftop_number_title">4) Battery:</h6>
            <p className="rooftop_number_desc mb-4">
              While it is an optional component, it becomes very important if we
              use a hybrid grid or an off-grid solar panel system. The battery
              stores the excess energy produced by the solar panels, which can
              be used at a later time. It can also turn the off-grid solar panel
              system into an emergency backup during load shedding.
            </p>
            <h6 className="rooftop_number_title">5) Meters:</h6>
            <p className="rooftop_number_desc mb-4">
              Meters track the energy that has been transferred to the
              National/local grid, and the energy used from it. Depending on the
              type of solar panel system, different types of metering devices
              are employed, such as net meter, bi-directional meter etc.
            </p>
            <p className="rooftop_desc mb-4">
              Depending on the how the power systems are interacting with the
              grid, a combination of the aforementioned components in
              conjunction with other specific equipment is required for optimal
              functioning of the solar power system. The different types of such
              systems, and the additional equipment they employ, are described
              briefly in the table below.
            </p>
            <div className="table-responsive">
              <table className="table table-bordered mb-4">
                <thead>
                  <tr className="align-middle">
                    <th scope="col">Type of System</th>
                    <th scope="col">How they work</th>
                    <th scope="col">Additional Equipment Required</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>On-grid/Grid tied</td>
                    <td>
                      They are connected to the power grid; hence such systems
                      can use both the solar energy and grid energy. When excess
                      current is produced by the solar panel system, it can be
                      let out to the power grid, and the power grid can supply
                      to the house as well when current is not being generated
                      (at night, for instance).
                    </td>
                    <td>
                      Two-way meter, which measures power going both ways;
                      grid-tie inverters are used to increase the output voltage
                      for excess electricity to flow out to the grid.
                    </td>
                  </tr>
                  <tr>
                    <td>Off grid</td>
                    <td>
                      It is not at all connected with the power grid, and hence
                      all the power generated by the solar panels is for captive
                      consumption. The excess energy generated is stored in
                      battery banks, from where it can be used as and when
                      required.
                    </td>
                    <td>
                      Battery bank, backup generator, off-grid inverter, DC
                      disconnect, and charge controllers. DC disconnect is
                      installed between the battery bank and the inverter, and
                      is used to switch off the current flowing between these
                      components. This helps in maintenance, fire prevention,
                      and troubleshooting. Charge controllers are important to
                      control the speed of battery charging.
                    </td>
                  </tr>
                  <tr>
                    <td>Hybrid grid</td>
                    <td>
                      A combination of on-grid and off-grid systems, it can
                      store the excess energy generated by solar, and can also
                      contribute to the power grid as and when required.
                    </td>
                    <td>
                      Battery bank, grid-tie inverter, two-way meters, charge
                      controllers, and DC disconnect.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 className="rooftop_subtitle">
              Maximizing Solar Energy Production
            </h5>
            <p className="rooftop_desc mb-4">
              Several factors influence the efficiency and effectiveness of
              rooftop solar panels in harnessing solar energy. The orientation
              and tilt angle of the solar panels play a crucial role in
              maximizing sunlight exposure throughout the day. Ideally, solar
              panels should face south in the northern hemisphere or north in
              the southern hemisphere to capture the most sunlight.
              Additionally, minimizing shading from nearby trees, buildings, or
              other obstructions is essential to ensure optimal solar energy
              production.
            </p>
            <h5 className="rooftop_subtitle">Conclusion</h5>
            <p className="rooftop_desc mb-0">
              Rooftop solar panels offer a practical and sustainable solution
              for generating electricity while reducing reliance on fossil fuels
              and lowering carbon emissions. By harnessing the power of the sun
              through the photovoltaic effect, solar panel owners can not only
              save money on their electricity bills but also contribute to a
              cleaner and greener future. As technology continues to advance and
              solar energy becomes increasingly accessible, rooftop solar panels
              are poised to play a significant role in shaping the energy
              landscape for years to come.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog1;
