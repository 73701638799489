import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import StateValue from "../data/state.json";
import axios from "axios";

const Blog3 = () => {
  const [formData, setFormData] = useState({
    systemSize: "",
    type: "",
    pageType: "",
    name: "",
    email: "",
    phoneNumber: "",
    state: "",
    city: "",
  });

  const location = useLocation();

  const navigation = useNavigate();

  const stateNames = Object.keys(StateValue);

  useEffect(() => {
    let pageType = "";
    if (location.pathname === "/blog/pm-surya-ghar-yojana") {
      pageType = "pm-surya-ghar-yojana";
    } else if (location.pathname === "/blog/Cost-of-solar-on-grid-system") {
      pageType = "cost-of-solar-on-grid-system";
    } else if (location.pathname === "/") {
      pageType = "Home";
    }

    setFormData({
      ...formData,
      pageType: pageType,
    });
  }, [location.pathname]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      const input = value.replace(/\D/g, "").slice(0, 15);
      setFormData((prevData) => ({
        ...prevData,
        [name]: input,
      }));
    } else if (name === "state") {
      setFormData((prevData) => ({
        ...prevData,
        state: value,
        city: "",
      }));
    } else if (name === "city") {
      setFormData((prevData) => ({
        ...prevData,
        city: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://admin.solarvipani.com/user-quote",
        formData
      );

      if (!response.data.res) {
        toast.error("Quate is not added!");
      } else {
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          state: "",
          city: "",
          type: "",
          systemSize: "",
          pageType: location.pathname,
        });

        navigation("/thanks");
      }
    } catch (error) {
      toast.error("Error fetch form data" + error.message);
    }
  };

  const selectedStateData = formData.state
    ? StateValue[formData.state].data
    : [];
  return (
    <>
      <div className="rooftop_main blog-main">
        <div className="container">
          <h4 className="rooftop_title">
            What is the approximate cost of a Solar On Grid System?
          </h4>
          <div className="rooftop_content">
            <p className="rooftop_desc mb-4">
              A Solar On Grid System, is a type of solar energy system that is
              connected to the grid. In this setup, solar panels generate
              electricity from sunlight, which is then used to power the
              electrical appliances and devices in a home or building. Any
              excess electricity produced by the solar panels is fed back into
              the grid, often earning credits or compensation from the utility
              company through net metering. Such a system is recommended for
              homes and businesses where there are no or a few power outages.
            </p>
            <h5 className="rooftop_subtitle mb-4">
              Approximate cost of Solar On Grid System installation:
            </h5>
            <div className="row">
              <div className="col-md-5">
                <div className="table-responsive">
                  <table className="table table-bordered mb-4">
                    <thead>
                      <tr className="align-middle">
                        <th scope="col">Size of Solar On Grid System</th>
                        <th scope="col">Approximate cost in Rs</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1kW</td>
                        <td>72k - 77k</td>
                      </tr>
                      <tr>
                        <td>2kW</td>
                        <td>144k - 158k</td>
                      </tr>
                      <tr>
                        <td>3kW</td>
                        <td>216k - 240k</td>
                      </tr>
                      <tr>
                        <td>4kW</td>
                        <td>268k - 296k</td>
                      </tr>
                      <tr>
                        <td>5kW</td>
                        <td>336k -370k</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <p className="rooftop_desc mb-4">
              For the requirements above 5kW, the cost per kW is approximately
              Rs 60k to Rs 65k
            </p>
            <h5 className="rooftop_subtitle">
              Components of Solar On Grid System installation costing:
            </h5>
            <ul className="blog_points">
              <li>
                Cost of Equipment (Solar Panels, Inverters, Mounting Hardware)
              </li>
              <li>Installation Labor Costs </li>
              <li>Permitting and Inspection Fees</li>
              <li>Additional Expenses (Wiring, Conduit, Monitoring Systems)</li>
              <li>GST</li>
            </ul>
            <p className="rooftop_desc mb-4">
              Apart from the above costs, there is a net metering cost which is
              around Rs 8k to Rs 14k
            </p>
            <h5 className="rooftop_subtitle">
              Equipments of Solar On Grid System:
            </h5>
            <ul className="blog_points">
              <li>
                Solar Panels (Photovoltaic Modules): These panels capture
                sunlight and convert it into electricity using photovoltaic
                cells made of semiconductor materials like silicon.
              </li>
              <li>
                Inverter: The inverter converts the direct current (DC)
                electricity produced by the solar panels into alternating
                current (AC) electricity, which is compatible with the
                electrical grid and can power household appliances.
              </li>
              <li>
                Mounting Structure: This component includes the racks, rails,
                and supports that secure the solar panels to the roof or ground.
                It ensures proper orientation and tilt for optimal sunlight
                exposure.
              </li>
              <li>
                Grid Connection and Metering System: This system allows the
                solar system to be connected to the grid. It includes a
                bi-directional meter that measures the flow of electricity
                between the solar system and the grid, enabling net metering.
              </li>
            </ul>
          </div>
          <div className="blog-right-side mt-5">
            <form onSubmit={handleSubmit}>
              <div className="text mb-3">
                <h5>Book Consultation Call to get a Quote</h5>
              </div>
              <div className="form_fields row">
                <div className="col-12 mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="city" className="form-label">
                    State
                  </label>
                  <select
                    className="form-select form-select-lg mb-3"
                    id="state"
                    name="state"
                    onChange={handleChange}
                    value={formData.state}
                    required
                  >
                    <option value={""}>Select</option>
                    {stateNames.map((v, i) => (
                      <option value={v} key={i}>
                        {StateValue[v].name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <select
                    className="form-select form-select-lg mb-3"
                    id="city"
                    name="city"
                    required
                    onChange={handleChange}
                    value={formData.city}
                  >
                    <option value={"1"}>Select</option>
                    {selectedStateData.map((district, i) => (
                      <option value={district} key={i}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="type" className="form-label">
                      Type
                    </label>
                    <select
                      className="form-select form-select-lg mb-3"
                      id="type"
                      name="type"
                      required
                      onChange={handleChange}
                      value={formData.type}
                    >
                      <option value="">Select</option>
                      <option value="Residential - Independant home">
                        Residential - Independant home
                      </option>
                      <option value="Residential - Apartment/Housing society">
                        Residential - Apartment/Housing society
                      </option>
                      <option value="Commercial/Business">
                        Commercial/Business
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <button type="submit" className="submit_btn w-100">
                    Get Quote
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog3;
