import React from "react";

const Blog4 = () => {
  return (
    <>
      <div className="rooftop_main">
        <div className="container">
          <h4 className="rooftop_title">
            What Appliances can be run on Solar?
          </h4>
          <div className="rooftop_content">
            <p className="rooftop_desc mb-4">
              A variety of appliances can be powered by an on-grid solar system
              at home. Here's a list of common household appliances that can
              typically be run using solar energy:
            </p>
            <h6 className="rooftop_number_title mb-4">
              1. Lights:
              <span>
                LED or CFL light bulbs for indoor and outdoor lighting.
              </span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              2. Television and entertainment systems:
              <span>
                LED TVs, DVD players, gaming consoles, and sound systems.
              </span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              3. Kitchen appliances:
              <span>
                Refrigerator, freezer, microwave, toaster, blender, coffee
                maker, electric kettle, and dishwasher.
              </span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              4. Heating and cooling systems:
              <span>
                Air conditioning units, electric fans, and space heaters.
              </span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              5. Laundry appliances:
              <span>Washing machine and electric dryer.</span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              6. Electronics:
              <span>
                Computers, laptops, tablets, smartphones, printers, and
                chargers.
              </span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              7. Home office equipment:
              <span>Printers, scanners, and fax machines.</span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              8. Small power tools:
              <span>
                Electric drills, saws, sanders, and other handheld power tools.
              </span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              9. Water pumps:
              <span>Well pumps, pool pumps, and fountain pumps.</span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              10. <span className="ps-0">Garage door openers and electric gates.</span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              11. Security systems:
              <span>CCTV cameras, motion sensors, and alarms.</span>
            </h6>
            <h6 className="rooftop_number_title mb-4">
              12. Home automation systems:
              <span>
                Smart thermostats, smart lighting systems, and smart home hubs.
              </span>
            </h6>
            <p className="rooftop_desc mb-4">
              It's essential to properly size the solar system based on the
              energy requirements of these appliances and the household's
              overall electricity consumption.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog4;
