import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import StateValue from "../data/state.json";
import { useNavigate } from "react-router-dom";

const Business = () => {
  const navigation = useNavigate();

  const [state, setState] = useState({
    selectedState: "",
    selectedDistrict: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    mapPin: "",
    phoneNumber: "",
    email: "",
    website: "",
    gstnNumber: "",
    type: "",
    state: "",
    district: "",
  });

  const stateNames = Object.keys(StateValue);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const input = value.replace(/\D/g, "").slice(0, 15);
      setFormData((prevData) => ({
        ...prevData,
        [name]: input,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (name === "state") {
      setState((prevData) => ({
        ...prevData,
        selectedState: value,
        selectedDistrict: "",
      }));
    } else if (name === "district") {
      setState((prevData) => ({
        ...prevData,
        selectedDistrict: value,
      }));
    }
  };

  const selectedStateData = state.selectedState
    ? StateValue[state.selectedState].data
    : [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const userId = "";
    // const clickTime = new Date(Date.now()).toLocaleString();
    // console.log({ userId, page: "BusinessForm", clickTime });

    try {
      const response = await axios.post(
        "https://admin.solarvipani.com/business/add-request",
        formData
      );

      if (!response.data.res) {
        toast.error("Please Fill Up All Details");
      } else {
        navigation("/congratulations-detail");
        setFormData({
          name: "",
          address: "",
          mapPin: "",
          phoneNumber: "",
          email: "",
          website: "",
          gstnNumber: "",
          type: "",
          state: "",
          district: "",
        });
        setState({
          selectedState: "",
          selectedDistrict: "",
        });
      }
      return response.data;
    } catch (error) {
      toast.error(error);
    }
  };

  // const userId = "";
  // const clickTime = new Date(Date.now()).toLocaleString();
  // console.log({ userId, page: "business", clickTime });
  return (
    <section className="home_section">
      <div className="container">
        <div className="text business_detail">
          <h5>
            Are you a Rooftop Solar Panel Installer looking to grow your
            business ?
          </h5>
          <p>
            Solarvipani.com receives visits from large number of individuals who
            have high intent to install rooftop solar panel at their homes and
            businesses. The platform aims to consistently provide educational
            resources and credible information to users to help them make
            correct decisions with confidence. Be part of one of the largest
            Online Directory of Solar Panel Installers on Solarvipani.com.
          </p>

          <h5>Benefits :</h5>
          <p>
            <b>1.</b> Increased Online Visibility
          </p>
          <p>
            <b>2.</b> No cost of Dictionary listing
          </p>
          <p>
            <b>3.</b> Cost-Effective Marketing Strategy
          </p>
          <p>
            <b>4.</b> Improved Credibility and Trustworthiness
          </p>
          <p>
            <b>5.</b> Enhanced Local SEO
          </p>
          <p>
            <b>6.</b> Opportunity to partner with Solarvipani.com for your area
          </p>
          <p className="mb-0 mt-4">
            <span className="d-inline-block" style={{fontSize: "1.25rem", fontWeight: "600"}}>Bonus:</span> Few Directory listings
            will get access to leads free of cost for limited period of time.
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="form_area form_border">
              <div className="text">
                <h5>Get listed by filling a form below</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form_fields row">
                  <div className="mb-3 col-12">
                    <label htmlFor="name" className="form-label">
                      Business Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter Business Name"
                      required
                    />
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="Enter Address"
                      value={formData.address}
                      onChange={handleChange}
                      id="adress"
                      required
                    />
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="map" className="form-label">
                      Map Pin
                    </label>
                    <input
                      type="text"
                      name="mapPin"
                      className="form-control"
                      placeholder="Enter Map Pin"
                      value={formData.mapPin}
                      onChange={handleChange}
                      id="mapPin"
                      required
                    />
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      className="form-control"
                      id="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="Enter Business Mobile"
                      required
                    />
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter Business Email"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label htmlFor="website" className="form-label">
                      Website
                    </label>
                    <input
                      type="text"
                      name="website"
                      className="form-control"
                      value={formData.website}
                      onChange={handleChange}
                      placeholder="Enter Website"
                      id="website"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label htmlFor="gstnNumber" className="form-label">
                      GSTN Number
                    </label>
                    <input
                      type="text"
                      name="gstnNumber"
                      className="form-control"
                      value={formData.gstnNumber}
                      onChange={handleChange}
                      placeholder="Enter GSTN Number"
                      id="gstnNumber"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label htmlFor="type" className="form-label">
                      Business Type
                    </label>
                    <select
                        className="form-select"
                        id="type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                      >
                        <option value={""}>Select</option>
                        <option value={"Solar panel installer"}>Solar panel installer</option>
                        <option value={"Solar equipment supplier"}>Solar equipment supplier</option>
                        <option value={"Both - Installer and equipment Supplier"}>Both - Installer and equipment Supplier</option>
                        <option value={"Other"}>Others</option>
                      </select>
                  </div>
                  <div className="mb-3 col-12">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select
                      className="form-select"
                      id="state"
                      name="state"
                      onChange={handleChange}
                      value={state.selectedState}
                    >
                      <option value={""}>Select</option>
                      {stateNames.map((v, i) => (
                        <option value={v} key={i}>
                          {StateValue[v].name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedStateData.length > 0 ? (
                    <div className="mb-3 col-12">
                      <label htmlFor="district" className="form-label">
                        District
                      </label>
                      <select
                        className="form-select"
                        id="district"
                        name="district"
                        onChange={handleChange}
                        value={state.selectedDistrict}
                      >
                        <option value={""}>Select</option>
                        {selectedStateData.map((district, i) => (
                          <option value={district} key={i}>
                            {district}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <button type="submit" className="bussiness_submit_btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Business;
