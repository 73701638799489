import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const input = value.replace(/\D/g, "").slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        [name]: input,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://admin.solarvipani.com/user-queries",
        formData
      );

      toast.success("successfully form data add.");

      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      });

      return response.data;
    } catch (error) {
      toast.error("Error fetch form data" + error.message);
    }
  };

  return (
    <section className="contact_section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="form_area">
              <form>
                <div className="text">
                  <h5>Send A Message</h5>
                  <p>Feel free to reach out for your queries </p>
                </div>
                <div className="form_fields">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      id="email"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone
                    </label>

                    <input
                      type="tel"
                      name="phoneNumber"
                      className="form-control"
                      id="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      pattern="[0-9]*"
                      maxLength={10}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      name="message"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={3}
                      required
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="submit_btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
