import React, { useEffect, useState } from "react";
import ElectricData from "../data/electricity.json";
import toast from "react-hot-toast";

const initialState = {
  appliancePower: 0,
  averageUsageHoursPerDay: 0,
  usagePerCapacity: 0,
};

const powerState = {
  numberOfAppliances: 1,
  electricityRate: 9,
};

const staticData = [
  {
    id: 1,
    appliance: "Light bulb (LED)",
    quantity: 5,
    consumptionPerDay: 0.28,
    electriConsumPerMonth: 8.4,
    load: 35,
    electricityCost: 0,
  },
  {
    id: 2,
    appliance: "Fan",
    quantity: 3,
    consumptionPerDay: 0.38,
    electriConsumPerMonth: 11.4,
    load: 75,
    electricityCost: 0,
  },
  {
    id: 3,
    appliance: "Geyser",
    quantity: 2,
    consumptionPerDay: 9,
    electriConsumPerMonth: 270,
    load: 6000,
    electricityCost: 0,
  },
  {
    id: 4,
    appliance: "Refrigerator",
    quantity: 1,
    consumptionPerDay: 3.6,
    electriConsumPerMonth: 108,
    load: 750,
    electricityCost: 0,
  },
  {
    id: 5,
    appliance: "Television",
    quantity: 1,
    consumptionPerDay: 0.32,
    electriConsumPerMonth: 9.6,
    load: 100,
    electricityCost: 0,
  },
  {
    id: 6,
    appliance: "Washing machine",
    quantity: 1,
    consumptionPerDay: 0.18,
    electriConsumPerMonth: 5.39,
    load: 800,
    electricityCost: 0,
  },
  {
    id: 7,
    appliance: " Mixer-Grinder",
    quantity: 1,
    consumptionPerDay: 0.03,
    electriConsumPerMonth: 0.89,
    load: 200,
    electricityCost: 0,
  },
];

const Electric = () => {
  const [userData, setUserData] = useState(initialState);
  const [powerData, setPowerData] = useState(powerState);
  const [electricityConsumptionPerDay, setElectricityConsumptionPerDay] =
    useState("");
  const [electricityConsumptionPerMonth, setElectricityConsumptionPerMonth] =
    useState("");
  const [load, setLoad] = useState("");
  const [additionElectricityCost, setAdditionElectricityCost] = useState("");
  const [appliance, setAppliance] = useState("");
  const [tableData, setTableData] = useState(() => {
    const savedData =
      JSON.parse(localStorage.getItem("electricityTableData")) || [];
    if (savedData.length === 0) {
      return staticData;
    }
    return savedData;
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "Select") {
      setUserData(initialState);
    } else {
      const selectedAppliance = ElectricData.find(
        (appliance) => appliance.id === value
      );
      setAppliance(selectedAppliance);
      if (selectedAppliance) {
        setUserData((prevData) => ({
          ...prevData,
          appliancePower: selectedAppliance.appliancePower,
          averageUsageHoursPerDay: selectedAppliance.averageUsageHoursPerDay,
          usagePerCapacity: selectedAppliance.usagePerCapacity,
        }));
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setPowerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addToLocalData = () => {
    if (
      appliance.name &&
      powerData.numberOfAppliances &&
      electricityConsumptionPerDay &&
      electricityConsumptionPerMonth &&
      load &&
      additionElectricityCost
    ) {
      const isDuplicate = tableData.some(
        (item) =>
          item.appliance === appliance.name &&
          item.quantity === powerData.numberOfAppliances &&
          item.consumptionPerDay === electricityConsumptionPerDay &&
          item.electriConsumPerMonth === electricityConsumptionPerMonth &&
          item.load === load &&
          item.electricityCost === additionElectricityCost
      );

      if (!isDuplicate) {
        const newItem = {
          id: tableData.length + 1,
          appliance: appliance.name,
          quantity: Number(powerData.numberOfAppliances),
          consumptionPerDay: electricityConsumptionPerDay,
          electriConsumPerMonth: electricityConsumptionPerMonth,
          load: load,
          electricityCost: additionElectricityCost,
        };

        const newTableData = [...tableData, newItem];
        setTableData(newTableData);
        saveDataToLocalStorage(newTableData);
      } else {
        toast.error("Duplicate data");
      }
    } else {
      toast.error("Incomplete data");
    }
  };

  const saveDataToLocalStorage = (data) => {
    localStorage.setItem("electricityTableData", JSON.stringify(data));
  };

  const deleteItem = (itemId) => {
    const updatedTableData = tableData.filter((item) => item.id !== itemId);
    setTableData(updatedTableData);
    saveDataToLocalStorage(updatedTableData);
  };

  const totalCalculation = () => {
    let totalQuantity = 0;
    let totalConsumptionPerDay = 0;
    let totalConsumptionPerMonth = 0;
    let totalLoad = 0;
    let totalelectricityCost = 0;

    tableData.map((item) => {
      totalQuantity += Number(item.quantity);
      totalConsumptionPerDay += Number(item.consumptionPerDay);
      totalConsumptionPerMonth += Number(item.electriConsumPerMonth);
      totalLoad += Number(item.load);
      totalelectricityCost += Number(item.electricityCost);
    });
    return {
      totalQuantity,
      totalConsumptionPerDay,
      totalConsumptionPerMonth,
      totalLoad,
      totalelectricityCost,
    };
  };

  const totalValues = totalCalculation();

  useEffect(() => {
    totalCalculation();
  }, [tableData]);

  //   useEffect(() => {
  //     const savedData =
  //       JSON.parse(localStorage.getItem("electricityTableData")) || [];
  //     setTableData(savedData);
  //   }, []);

  useEffect(() => {
    const calculation = () => {
      if (powerData.electricityRate && powerData.numberOfAppliances) {
        const NuOfAppliance = Number(powerData.numberOfAppliances);
        const ElectRate = Number(powerData.electricityRate);

        // Electricity Consumption (kWh) per day
        const electricityConsumptionPerDayValue = (
          userData.averageUsageHoursPerDay *
          (userData.appliancePower / 1000) *
          (userData.usagePerCapacity / 100) *
          NuOfAppliance
        ).toFixed(2);
        const electricityConsumptionPerDayNumber = parseFloat(
          electricityConsumptionPerDayValue
        );

        setElectricityConsumptionPerDay(electricityConsumptionPerDayNumber);

        // Electricity Consumption (kWh) per month
        const electricityConsumptionPerMonthValue =
          electricityConsumptionPerDayNumber * 30;
        setElectricityConsumptionPerMonth(electricityConsumptionPerMonthValue);

        // Load
        const loadValue = userData.appliancePower * NuOfAppliance;
        setLoad(loadValue);

        // Addition electricity cost
        const additionElectricityCostValue = (
          electricityConsumptionPerMonthValue * ElectRate
        ).toFixed(2);
        const additionElectricityCostNumber = parseFloat(
          additionElectricityCostValue
        );
        setAdditionElectricityCost(additionElectricityCostNumber);
      }
    };

    calculation();
  }, [userData, powerData]);

  // const userId = "";
  // const clickTime = new Date(Date.now()).toLocaleString();
  // console.log({ userId, page: "Electricity Consumption Estimator", clickTime });

  return (
    <>
      <div className="electric_calculator_section">
        <div className="container">
          <div className="top_text">
            <h1>Electricity Consumption Estimator</h1>
          </div>
          <div className="row">
            <div className="col-xl-7">
              <div className="electricity_table py-4 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">No.</th>
                      <th className="text-center">Appliance</th>
                      <th className="text-center">Qty</th>
                      <th className="text-center">
                        Ele. con. per day (kWh/day)
                      </th>
                      <th className="text-center">
                        Ele. con. per month (kWh/month)
                      </th>
                      <th className="text-center">Load</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="total-values">
                      <td className="text-center align-middle" colSpan={2}>
                        Total
                      </td>
                      <td className="text-center align-middle">
                        {totalValues.totalQuantity}
                      </td>
                      <td className="text-center align-middle">
                        {totalValues.totalConsumptionPerDay.toFixed(2)}
                      </td>
                      <td className="text-center align-middle">
                        {totalValues.totalConsumptionPerMonth}
                      </td>
                      <td className="text-center align-middle">
                        {totalValues.totalLoad}
                      </td>
                      <td className="text-center align-middle"></td>
                    </tr>
                    {tableData.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle">
                          {index + 1}
                        </td>
                        <td className="text-center align-middle">
                          {item.appliance}
                        </td>
                        <td className="text-center align-middle">
                          {item.quantity}
                        </td>
                        <td className="text-center align-middle">
                          {item.consumptionPerDay.toFixed(2)}
                        </td>
                        <td className="text-center align-middle">
                          {item.electriConsumPerMonth.toFixed(2)}
                        </td>
                        <td className="text-center align-middle">
                          {item.load}
                        </td>
                        <td className="text-center align-middle">
                          <button
                            className="electricity_delete_btn"
                            onClick={() => deleteItem(item.id)}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="solar_calculator_left_side mb-4">
                <form>
                  <div className="mb-4">
                    <label htmlFor="typicalAppliance" className="form-label">
                      Typical appliance
                    </label>
                    <select
                      className="form-select"
                      id="typicalAppliance"
                      onChange={handleChange}
                      name="typicalAppliance"
                    >
                      <option>Select</option>
                      <option disabled className="fw-bold">
                        Home appliances:
                      </option>
                      <option value={1}>Air Conditioner (HVAC)</option>
                      <option value={2}>Air conditioner (window unit)</option>
                      <option value={3}>Heater (home)</option>
                      <option value={4}>Fan (ceiling, table)</option>
                      <option value={5}>Light bulb (LED)</option>
                      <option value={6}>Light bulb (incandescent)</option>
                      <option value={7}>Geyser</option>
                      <option disabled className="fw-bold">
                        Kitchen appliances:
                      </option>
                      <option value={8}>Refrigerator</option>
                      <option value={9}>Microwave oven</option>
                      <option value={10}>Dishwasher</option>
                      <option value={11}>Coffee maker</option>
                      <option value={12}>Toaster</option>
                      <option value={13}>Electric kettle</option>
                      <option value={14}>Electric cooker</option>
                      <option value={15}>Mixer-Grinder</option>
                      <option disabled className="fw-bold">
                        Other appliances:
                      </option>
                      <option value={16}>
                        Electric vehicle charger (2 Wheeler)
                      </option>
                      <option value={17}>
                        Electric vehicle charger (4 Wheeler)
                      </option>
                      <option value={18}>Television</option>
                      <option value={19}>Washing machine</option>
                      <option value={20}>Clothes dryer</option>
                      <option value={21}>Clothes iron</option>
                      <option value={22}>Hair dryer</option>
                      <option value={23}>Desktop computer</option>
                      <option value={24}>Laptop computer</option>
                      <option value={25}>Smart phone charger</option>
                      <option value={26}>Water pump/motor</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="numberOfAppliances" className="form-label">
                      Number of appliances
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="numberOfAppliances"
                      placeholder="Enter number of appliances"
                      name="numberOfAppliances"
                      value={powerData.numberOfAppliances}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="electricityRate" className="form-label">
                      Electricity Rate (Rs per kWh)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="electricityRate"
                      placeholder="Enter electricity rate"
                      name="electricityRate"
                      value={powerData.electricityRate}
                      onChange={handleInputChange}
                    />
                  </div>
                </form>
              </div>
              <div className="solar_calculator_right_side">
                <h3>Electricity Power</h3>
                <div className="mb-3 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Appliance Power</p>
                  </div>
                  <div className="right_side_text text-end">
                    <input
                      type="number"
                      name="appliancePower"
                      value={userData.appliancePower}
                      onChange={handleInputChange}
                      className="electric_input_field"
                    />
                    <span className="electricity_field_name">Watt</span>
                  </div>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Average Usage hours per day</p>
                  </div>
                  <div className="right_side_text text-end">
                    <input
                      type="number"
                      name="averageUsageHoursPerDay"
                      value={userData.averageUsageHoursPerDay}
                      onChange={handleInputChange}
                      className="electric_input_field"
                    />
                    <span className="electricity_field_name">
                      hours per day
                    </span>
                  </div>
                </div>
                <div className="mb-4 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Usage per capacity</p>
                  </div>
                  <div className="right_side_text text-end">
                    <input
                      type="number"
                      name="usagePerCapacity"
                      value={userData.usagePerCapacity}
                      onChange={handleInputChange}
                      className="electric_input_field"
                    />
                    <span className="electricity_field_name">% capacity</span>
                  </div>
                </div>
                <h3>Electricity Consumption</h3>
                <div className="mb-3 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Electricity Consumption (kWh) per day</p>
                  </div>
                  <div className="right_side_text text-end">
                    <h6 className="m-0">
                      {electricityConsumptionPerDay.length === 0
                        ? 0
                        : electricityConsumptionPerDay}{" "}
                      units/day
                    </h6>
                  </div>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">
                      Electricity Consumption (kWh) per month
                    </p>
                  </div>
                  <div className="right_side_text text-end">
                    <h6 className="m-0">
                      {electricityConsumptionPerMonth.length === 0
                        ? 0
                        : electricityConsumptionPerMonth.toFixed(2)}{" "}
                      units/month
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="left_side_text text-start">
                    <p className="m-0">Load</p>
                  </div>
                  <div className="right_side_text text-end">
                    <h6 className="m-0">{load.length === 0 ? 0 : load} Watt</h6>
                  </div>
                </div>
                {/* <div className='mb-1 d-flex justify-content-between'>
                                    <div className="left_side_text text-start">
                                        <p className='m-0'>Addition electricity cost</p>
                                    </div>
                                    <div className="right_side_text text-end">
                                        <h6 className='m-0'>₹{additionElectricityCost.length === 0 ? 0 : additionElectricityCost} Rupees</h6>
                                    </div>
                                </div> */}
              </div>
              <div className="right_side_button text-end mt-4">
                <button
                  className="electricity_add_btn"
                  onClick={addToLocalData}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          {/* {
                        tableData.length > 0 ?
                            <div className="electricity_table py-4 table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Appliance</th>
                                            <th>Quantity</th>
                                            <th>Ele. con. per day (kWh/day)</th>
                                            <th>Ele. con. per month (kWh/month)</th>
                                            <th>Load</th>
                                            <th>Addition electricity cost</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((item, index) => (
                                            <tr key={index}>
                                                <td className='text-center align-middle'>{index + 1}</td>
                                                <td className='text-center align-middle'>{item.appliance}</td>
                                                <td className='text-center align-middle'>{item.quantity}</td>
                                                <td className='text-center align-middle'>{item.consumptionPerDay}</td>
                                                <td className='text-center align-middle'>{item.electriConsumPerMonth}</td>
                                                <td className='text-center align-middle'>{item.load}</td>
                                                <td className='text-center align-middle'>{item.electricityCost}</td>
                                                <td className='text-center align-middle'>
                                                    <button className='electricity_delete_btn' onClick={() => deleteItem(item.id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className='text-center align-middle' colSpan={2}>Total</td>
                                            <td className='text-center align-middle'>{totalValues.totalQuantity}</td>
                                            <td className='text-center align-middle'>{totalValues.totalConsumptionPerDay}</td>
                                            <td className='text-center align-middle'>{totalValues.totalConsumptionPerMonth}</td>
                                            <td className='text-center align-middle'>{totalValues.totalLoad}</td>
                                            <td className='text-center align-middle'>{totalValues.totalelectricityCost}</td>
                                            <td className='text-center align-middle'></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : ""
                    } */}
        </div>
      </div>
    </>
  );
};

export default Electric;
