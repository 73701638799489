import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const [id, setId] = useState(null);
  const [state, setState] = useState({
    title: "",
    description: "",
    details: "",
  });

  useEffect(() => {
    const blogId = localStorage.getItem("blogID");
    setId(blogId);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://admin.solarvipani.com/blog-details/${id}`
      );
      setState(response.data.data);
    } catch (error) {
      toast.error("Error fetching business details: " + error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);
  return (
    <div className="rooftop_main">
      <div className="container">
        <h4 className="rooftop_title">{state.title}</h4>
        <div className="rooftop_content">
          <p className="rooftop_desc mb-4">{state.description}</p>
          <div
            className="rooftop_desc mb-4"
            dangerouslySetInnerHTML={{ __html: state.details }}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
