import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import ReactStars from "react-rating-stars-component";
import { useLocation } from "react-router-dom";

const Review = () => {
  const location = useLocation();
  const { name, id } = location.state || {};
  const [formData, setFormData] = useState({
    id: id,
    star: 0,
    reviewTitle: "",
    reviewDescription: "",
    displayName: "",
    pincode: "",
    mobileNumber: "",
    dateOfInstallation: "",
    sizeOfSystem: "",
    solarPanelBrand: "",
    inverterBrand: "",
    comment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobileNumber") {
      const input = value.replace(/\D/g, "").slice(0, 15);
      setFormData((prevData) => ({
        ...prevData,
        [name]: input,
      }));
    } else if (name === "pincode") {
      const input = value.replace(/\D/g, "").slice(0, 6);
      setFormData((prevData) => ({
        ...prevData,
        [name]: input,
      }));
    } else if (name === "sizeOfSystem") {
      const regex = /^(?:[1-9]\d*|0)?(?:\.[05])?$/;
      if (regex.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    if (formData.star === 0) {
      toast.error("Please provide a star rating.");
      return false;
    }
    if (!formData.reviewTitle.trim()) {
      toast.error("Review Title is required.");
      return false;
    }
    if (!formData.reviewDescription.trim()) {
      toast.error("Review Description is required.");
      return false;
    }
    if (!formData.pincode.trim()) {
      toast.error("Pincode is required.");
      return false;
    }
    if (!formData.dateOfInstallation.trim()) {
      toast.error("Date of Installation is required.");
      return false;
    }
    if (!formData.sizeOfSystem.trim()) {
      toast.error("Size of System is required.");
      return false;
    }
    if (!formData.solarPanelBrand.trim()) {
      toast.error("Solar Panel Brand is required.");
      return false;
    }
    if (!formData.inverterBrand.trim()) {
      toast.error("Inverter Brand is required.");
      return false;
    }
    if (!formData.mobileNumber.trim()) {
      toast.error("Mobile Number is required.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        "https://admin.solarvipani.com/business-review",
        formData
      );

      if (!response.data.res) {
        toast.error("Failed to submit review.");
      } else {
        setFormData({
          id: id,
          star: 0,
          reviewTitle: "",
          reviewDescription: "",
          displayName: "",
          pincode: "",
          mobileNumber: "",
          dateOfInstallation: "",
          sizeOfSystem: "",
          solarPanelBrand: "",
          inverterBrand: "",
          comment: "",
        });

        toast.success("Review submitted successfully!");
      }
    } catch (error) {
      toast.error("Error submitting form: " + error.message);
    }
  };

  return (
    <>
      <div className="review_section">
        <div className="container">
          <h3 className="review__title">
            Write a Review about your installation experience with for {name}
          </h3>
          <h3 className="review_star_rating_title mt-4">
            How will you rate your overall experience?
          </h3>
          <form onSubmit={handleSubmit} className="review_form">
            <div className="star-rating">
              <ReactStars
                count={5}
                isHalf={true}
                onChange={(newRating) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    star: newRating,
                  }))
                }
                size={24}
                activeColor="#ffd700"
                value={formData.star}
              />
            </div>
            <h3 className="review_form_title mt-2">
              Write a review so that other customers can understand reasons for
              your rating
            </h3>
            <label htmlFor="reviewTitle" className="form-label mt-4">
              Review Title:*
            </label>
            <input
              type="text"
              name="reviewTitle"
              id="reviewTitle"
              className="form-control"
              placeholder="Enter Review Title"
              required
              value={formData.reviewTitle}
              onChange={handleChange}
            />
            <label htmlFor="reviewDescription" className="form-label mt-4">
              Review Description:*
            </label>
            <input
              type="text"
              name="reviewDescription"
              id="reviewDescription"
              className="form-control"
              placeholder="Enter Review Description"
              required
              value={formData.reviewDescription}
              onChange={handleChange}
            />
            <label htmlFor="displayName" className="form-label mt-4">
              Your Display Name
            </label>
            <input
              type="text"
              name="displayName"
              id="displayName"
              className="form-control"
              placeholder="Enter Your Display Name"
              value={formData.displayName}
              onChange={handleChange}
            />
            <label htmlFor="pincode" className="form-label mt-4">
              Your Pin code:*
            </label>
            <input
              type="number"
              name="pincode"
              id="pincode"
              className="form-control"
              placeholder="Enter Your Pin code"
              required
              value={formData.pincode}
              onChange={handleChange}
            />
            <label htmlFor="dateOfInstallation" className="form-label mt-4">
              Date of Installation*
            </label>
            <input
              type="month"
              name="dateOfInstallation"
              id="dateOfInstallation"
              className="form-control"
              required
              value={formData.dateOfInstallation}
              onChange={handleChange}
            />
            <label htmlFor="sizeOfSystem" className="form-label mt-4">
              Size of System (kW):*
            </label>
            <input
              type="number"
              name="sizeOfSystem"
              id="sizeOfSystem"
              className="form-control"
              placeholder="Enter Size of System"
              value={formData.sizeOfSystem}
              onChange={handleChange}
              required
            />
            <label htmlFor="solarPanelBrand" className="form-label mt-4">
              Solar Panel Brand*
            </label>
            <select
              name="solarPanelBrand"
              id="solarPanelBrand"
              className="form-select"
              required
              value={formData.solarPanelBrand}
              onChange={handleChange}
            >
              <option value="1">Adani Solar (Drop-down)</option>
              <option value="2">Abc</option>
              <option value="3">pqr</option>
              <option value="4">xyz</option>
            </select>
            <label htmlFor="inverterBrand" className="form-label mt-4">
              Inverter Brand*
            </label>
            <select
              name="inverterBrand"
              id="inverterBrand"
              className="form-select"
              required
              value={formData.inverterBrand}
              onChange={handleChange}
            >
              <option value="1">Growatt (Drop-down)</option>
              <option value="2">Abc</option>
              <option value="3">pqr</option>
              <option value="4">xyz</option>
            </select>
            <label htmlFor="comment" className="form-label mt-4">
              Comment:
            </label>
            <textarea
              className="form-control"
              id="comment"
              rows="3"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
            ></textarea>
            <label htmlFor="mobileNumber" className="form-label mt-4">
              Mobile Number:*
            </label>
            <input
              type="number"
              name="mobileNumber"
              id="mobileNumber"
              className="form-control"
              placeholder="Enter Mobile Number"
              required
              value={formData.mobileNumber}
              onChange={handleChange}
            />
            <button type="submit" className="star-rating-btn my-3">
              Submit Review
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Review;
