import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <header className="sticky-header">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid header_container">

            <Link className="navbar-brand" to={"/"}>

              <img src="/img/flaticon.png" alt="logo" width={160} /></Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">

              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/solar"}>Solar System Calculator</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/electric"}>Electricity Consumption Estimator </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/solar-panel-installer-directory"}>Solar System Installers in Your Area </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/blogs"}>Blog </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/about"}>About Us </NavLink>
                </li>
              </ul>

            </div>
          </div>
        </nav>

      </header>
    </>
  )
}

export default Header