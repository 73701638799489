import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import StateValue from "../data/state.json";
import axios from "axios";
import { NavLink } from "react-router-dom";

const Directory2 = () => {
    const { districtParam } = useParams();
    const [state, setState] = useState({
      selectedState: "",
      selectedDistrict: "",
    });
    const [csvData, setCsvData] = useState([]);
    const [tbodyLength, setTbodyLength] = useState(0);
    const stateNames = Object.keys(StateValue);
    const navigation = useNavigate();
  
    const handleRedirect = async (id) => {
      try {
        const response = await axios.get(
          `https://admin.solarvipani.com/business-details/${id}`
        );
        const data = response.data.data;
        navigation(`/solar-panel-installer/${id}`, { state: data });
      } catch (error) {
        console.error("API Error with ID:", error);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      if (name === "state") {
        setState((prevData) => ({
          ...prevData,
          selectedState: value,
          selectedDistrict: "",
        }));
      } else if (name === "district") {
        setState((prevData) => ({
          ...prevData,
          selectedDistrict: value,
        }));
      }
    };
  
    const selectedStateData = state.selectedState
      ? StateValue[state.selectedState].data
      : [];
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            "https://admin.solarvipani.com/business-list"
          );
          setCsvData(response.data.data);
        } catch (error) {
          console.error("API Error:", error);
        }
      };
  
      fetchData();
    }, []);
  
    useEffect(() => {
      if (districtParam) {
        // Normalize districtParam to lowercase
        const normalizedDistrictParam = districtParam.toLowerCase();
        let foundState = "";
        let foundDistrict = "";
  
        Object.keys(StateValue).forEach((stateKey) => {
          const stateData = StateValue[stateKey].data;
          stateData.forEach((district) => {
            if (district.toLowerCase() === normalizedDistrictParam) {
              foundState = stateKey;
              foundDistrict = district;
            }
          });
        });
  
        if (foundState && foundDistrict) {
          setState({
            selectedState: foundState,
            selectedDistrict: foundDistrict,
          });
        }
      }
    }, [districtParam]);
  
    useEffect(() => {
      setTbodyLength(
        csvData.filter((v) => {
          const csvState = v.state?.trim().toLowerCase();
          const csvDistrict = v.district?.trim().toLowerCase();
          const stateState = state.selectedState?.trim().toLowerCase();
          const stateDistrict = state.selectedDistrict?.trim().toLowerCase();
  
          return csvState === stateState && csvDistrict === stateDistrict;
        }).length
      );
    }, [csvData, state]);
  
    return (
      <div className="solar_calculator_section">
        <div className="container">
          <div className="top_text d-flex align-items-center justify-content-between flex-wrap">
            <h1> Solar System Installers in Your Area </h1>
            <NavLink to={"/business-form"} className="btn btn-primary">
              Add Business
            </NavLink>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="solar_calculator_left_side">
                <form>
                  <div className="mb-4">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select
                      className="form-select"
                      id="state"
                      name="state"
                      onChange={handleChange}
                      value={state.selectedState}
                    >
                      <option value={""}>Select</option>
                      {stateNames.map((v, i) => (
                        <option value={v} key={i}>
                          {StateValue[v].name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedStateData.length > 0 ? (
                    <div className="mb-4">
                      <label htmlFor="district" className="form-label">
                        District
                      </label>
                      <select
                        className="form-select"
                        id="district"
                        name="district"
                        onChange={handleChange}
                        value={state.selectedDistrict}
                      >
                        <option value={"1"}>Select</option>
                        {selectedStateData.map((district, i) => (
                          <option value={district} key={i}>
                            {district}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
            {state.selectedDistrict.length > 0 ? (
              <div className="col-12 mt-5">
                <h4 className="search-result-title mb-4">Search results</h4>
                <div className="solar_calculator_right_side">
                  {tbodyLength > 0 ? (
                    <div className="row">
                      {csvData?.map((v, i) => {
                        const csvState = v.state?.trim();
                        const csvDistrict = v.district?.trim();
                        const stateState = state.selectedState?.trim();
                        const stateDistrict = state.selectedDistrict?.trim();
                        if (
                          csvState === stateState &&
                          csvDistrict === stateDistrict
                        ) {
                          return (
                            <div
                              className="col-lg-4 col-md-6 col-12 mb-3"
                              key={i}
                            >
                              <div className="card h-100">
                                <div className="card-body">
                                  {(v?.note !== "Blank" && v?.note) && (
                                    <h5
                                      className="card-sub-title"
                                      onClick={() => handleRedirect(v?.id)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {v?.note}
                                    </h5>
                                  )}
                                  <h5
                                    className="card-title"
                                    onClick={() => handleRedirect(v?.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {v?.name}
                                  </h5>
                                  <div
                                    className="card-text d-flex mb-3"
                                    onClick={() => handleRedirect(v?.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="me-2">
                                      <i className="fa-solid fa-phone"></i>
                                    </div>
                                    <div>{v?.phoneNumber}</div>
                                  </div>
                                  {v?.website ? (
                                    <div
                                      className="card-text d-flex mb-3"
                                      onClick={() => handleRedirect(v?.id)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="me-2">
                                        <i className="fa-solid fa-globe"></i>
                                      </div>
                                      <div>
                                        <a
                                          href={v?.website}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="ellipsis"
                                        >
                                          {v?.website.replace(
                                            /^(https?:\/\/)?|\/$/g,
                                            ""
                                          )}
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className="card-text d-flex"
                                    onClick={() => handleRedirect(v?.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="me-2">
                                      <i className="fa-solid fa-location-dot"></i>
                                    </div>
                                    <div>{v?.address ? v?.address : "----"}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <div className="text-center">
                      <h1>No data found</h1>
                    </div>
                  )}
                </div>
              </div>
            ) : (
                <div className="text-center mt-5">
                <h1 className="fs-3">No data found for {districtParam}</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

export default Directory2;