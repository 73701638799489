import React from "react";

const CongratulationsDetail = () => {
  // const userId = "";
  // const clickTime = new Date(Date.now()).toLocaleString();
  // console.log({ userId, page: "Congratulation", clickTime });
  return (
    <>
      <div className="container">
        <div className="congratulation">
          <div className="top_text mt-5">
            <h4>Congratulations! Your details have been received.</h4>
          </div>
          <div className="text">
            <h5 className="mb-3">What to Expect Next:</h5>
            <p className="mb-3">
              <b>1.</b> A representative may reach out to clarify on certain
              details in next 2-4 business days.
            </p>
            <p className="mb-3">
              <b>2.</b> Your business will be listed in the Directory of Solar
              Panel Installers on Solarvipani.com.
            </p>
            <p className="mb-3">
              <b>3.</b> Your business details will be displayed to all the users
              searching for your area.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CongratulationsDetail;
